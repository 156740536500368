import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionIntro from "../components/ui/SectionIntro/SectionIntro";
import ServiceCard from "../components/ui/Services/ServiceCard";

import homepageData from "../content/pages/homepage.json";
import servicePageData from "../content/pages/services.json";
import { getSingleImageBySrc, Images } from "../utils/findImage";

interface ServicesPageProps {
  data: {
    allImages: Images;
  };
}

const ServicesPage: React.FC<ServicesPageProps> = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={servicePageData.seo.seoTitle}
        description={servicePageData.seo.metaDescription}
        slug="services"
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-4xl text-white mb-0 leading-none">Services</h1>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-8 justify-items-center">
            <div className="col-span-full">
              <SectionIntro
                subHeading={homepageData.services.subHeadline}
                heading={homepageData.services.headline}
                className="text-center mb-8"
              >
                <p className="max-w-md mx-auto">
                  {homepageData.services.description}
                </p>
              </SectionIntro>
            </div>
            {homepageData.services.services.map((service) => (
              <ServiceCard
                key={service.serviceName}
                title={service.serviceName}
                image={getSingleImageBySrc(
                  service.image.src
                    .replace("/images/homepage/", "")
                    .replace(".jpg", ""),
                  data.allImages
                )}
                alt={service.image.alt}
                to={`/services/${service.linkedService}/`}
              >
                {service.serviceDescription}
              </ServiceCard>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServicesPage;

export const pageQuery = graphql`
  query($allImages: [String!]) {
    allImages: allFile(filter: { base: { in: $allImages } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          original {
            src
          }
        }
      }
    }
  }
`;
