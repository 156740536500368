import { ButtonBase } from "@material-ui/core";
import { Link } from "gatsby";
import {
  StaticImage,
  GatsbyImage,
  IGatsbyImageData,
} from "gatsby-plugin-image";
import React from "react";

interface ServiceCardProps {
  title: string;
  image?: IGatsbyImageData;
  alt: string;
  to: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  image,
  alt,
  to,
  children,
}) => {
  return (
    <div className="">
      <ButtonBase className="block group" component={Link} to={to}>
        <div className="mb-4">
          {typeof image === "undefined" ? (
            <StaticImage
              src="https://via.placeholder.com/300x200/333333/?text=%20"
              alt={alt}
              layout="fullWidth"
            />
          ) : (
            <GatsbyImage image={{ ...image, height: 0.7 }} alt={title} />
          )}
        </div>
        <div className="text-center px-3 pb-4">
          <h4 className="text-2xl font-semibold mb-2">{title}</h4>
          <p className="mb-2">{children}</p>
          <span className="text-primary-main group-hover:text-primary-dark font-medium">
            Learn more
          </span>
        </div>
      </ButtonBase>
    </div>
  );
};

export default ServiceCard;
