import { IGatsbyImageData } from "gatsby-plugin-image";

// export type Images = {
//   nodes: {
//     gatsbyImageData: IGatsbyImageData;
//     original: {
//       src: string;
//     };
//   }[];
// };

export type Images = {
  nodes: {
    relativePath: string;
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
      original: {
        src: string;
      };
    };
  }[];
};

// export const getImageArrayBySrc = (str: string, images: Images) => {
//   return images.nodes.filter((node) => node.original.src.includes(str));
// };

// export const getSingleImageBySrc = (str: string, images: Images) => {
//   return images.nodes.find((node) => node.original.src.includes(str))
//     ?.gatsbyImageData;
// };

export const getImageArrayBySrc = (str: string, images: Images) => {
  if (str.includes("images")) {
    str = str.replace("/images/", "");
  }

  return images.nodes.filter((node) => node.relativePath?.includes(str));
};

export const getSingleImageBySrc = (str: string, images: Images) => {
  if (str.includes("images")) {
    str = str.replace("/images/", "");
  }
  return images.nodes.find((node) => node.relativePath?.includes(str))
    ?.childImageSharp?.gatsbyImageData;
};
